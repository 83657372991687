import React from 'react'
import { Section, Stack, Text, Image, YapAssetImage, Gallery, Video, Calendar, Quiz, ActionButton, Info, ZStack } from '@yapstudios/yap-content-builder'
import { MessageHeaderComponent } from "./CollegeboardComponentLibrary.js"

class MessageCardComponent {
    constructor(title, subtitle) {
        this.logo = null//"https://cdn.cookielaw.org/logos/65a38c3f-4f1f-4acc-9e16-9a9cd7f86053/590c270b-d515-47ae-bbf8-a89440cb8a53/7d41f991-7471-407c-baf2-c567c0c26233/cb-logo2.png"
        this.image = null
        this.defaultTitle = title ?? "Lorem ipsum dolor sit amet, consectetur"
        this.type = 'message-card'
    }

    defaultProps() {
        return {
            title: null,
            subtitle: null
        }
    }

    decode(props, { style, isPreview }) {
        const title = props.title ?? this.defaultTitle
        const dark = props.dark
        const divider = { height: "1px", borderTop: "1px solid " + style.colors.separator, marginTop: "8px", marginBottom: "4px" }

        const font = { size: 17, weight: 'bold', color: "primary" }

        const cardStyle = {
            backgroundColor: style.colors.contentBackground,
            borderRadius: '14px',
            boxShadow: '0px 12px 27px #00000020'
        }

        const bookmarkIcon = (
            <svg width="12" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.61914 18.3789C1.06299 18.3789 0.739258 18.0137 0.739258 17.3662V2.95605C0.739258 1.37891 1.52783 0.57373 3.08838 0.57373H9.57959C11.1401 0.57373 11.9287 1.37891 11.9287 2.95605V17.3662C11.9287 18.0137 11.605 18.3789 11.0488 18.3789C10.6421 18.3789 10.4014 18.1465 9.6543 17.4243L6.40869 14.2285C6.37549 14.187 6.30078 14.187 6.25928 14.2285L3.01367 17.4243C2.2666 18.1465 2.02588 18.3789 1.61914 18.3789ZM2.2998 16.1377L5.89404 12.6514C6.15967 12.394 6.5083 12.394 6.77393 12.6514L10.3682 16.1377C10.4927 16.2539 10.6172 16.2207 10.6172 16.0547V2.97266C10.6172 2.25879 10.2437 1.88525 9.52148 1.88525H3.15479C2.42432 1.88525 2.05078 2.25879 2.05078 2.97266V16.0547C2.05078 16.2207 2.18359 16.2539 2.2998 16.1377Z" fill="#989898" />
            </svg>
        )

        const moreIcon = (
            <svg width="17" height="5" viewBox="0 0 17 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.51904 4.15723C1.60596 4.15723 0.858887 3.41846 0.858887 2.49707C0.858887 1.57568 1.60596 0.836914 2.51904 0.836914C3.45703 0.836914 4.2041 1.57568 4.2041 2.49707C4.2041 3.41846 3.45703 4.15723 2.51904 4.15723ZM8.68652 4.15723C7.77344 4.15723 7.03467 3.41846 7.03467 2.49707C7.03467 1.57568 7.77344 0.836914 8.68652 0.836914C9.60791 0.836914 10.3467 1.57568 10.3467 2.49707C10.3467 3.41846 9.60791 4.15723 8.68652 4.15723ZM14.854 4.15723C13.916 4.15723 13.1772 3.41846 13.1772 2.49707C13.1772 1.57568 13.916 0.836914 14.854 0.836914C15.7754 0.836914 16.5142 1.57568 16.5142 2.49707C16.5142 3.41846 15.7754 4.15723 14.854 4.15723Z" fill="#989898" />
            </svg>
        )

        const iconStyle = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px'
        }

        const logo = props.logo ?? this.logo
        const img = props.image ?? this.image
        const logoAvatar = props.logoAvatar

        // Support passing as src or asset object for backward compatibility
        const imgSrc = img ? (img.src ?? img) : null
        const logoSrc = logo ? (logo.src ?? logo) : null
        const logoAvatarSrc = logoAvatar ? (logoAvatar.src ?? logoAvatar) : null

        const bodyTextStyle = {
            display: '-webkit-box',
            overflow: 'hidden',
            "WebkitBoxOrient": "vertical",
            lineHeight: '1.4',
            fontWeight: 600,
            fontSize: '17px',
            textAlign: 'left',
            width: '150px',
            color: style.colors.primary
        }

        bodyTextStyle.WebkitLineClamp = 4

        /**
         * Placeholder Elements
         */
        const largeRectangle = {
            width: '152px',
            height: '28px',
            flexGrow: 1,
            borderRadius: '6px',
            backgroundColor: style.colors.placeholder
        }

        const mediumRectangle = {
            width: '100px',
            height: '9px',
            borderRadius: '6px',
            backgroundColor: style.colors.placeholder
        }

        const smallRectangle = {
            width: '52px',
            height: '9px',
            borderRadius: '6px',
            backgroundColor:  style.colors.placeholder
        }

        /**
         * Logo
         */
        let imageWidth  = 135
        let imageHeight = 136
        let emptyImage  = <div style={{
            width: `${imageWidth}px`,
            height: `${imageHeight}px`,
            borderRadius: '8px',
            backgroundColor: style.colors.placeholder,
        }}></div>

        let placeholderStyle = {
            ...largeRectangle,
            backgroundImage: 'url(' + style.images.placeholder + ')',
            backgroundSize: '16px 16px',
        }
        let emptyLogo = <div key={"medium1"} style={props.placeholder ? placeholderStyle : largeRectangle}/>
        let logoElement = null

        if ((logoSrc && props.logoType == null) || (props.logoType == 'wordmark') ) {
            
            logoElement = <YapAssetImage asset={{ src: logoSrc, crop: logo ? logo.crop : null, description: props.logoDescription }} maxWidth={150} maxHeight={26} cornerRadius={8}></YapAssetImage>

        } else if ( (logoAvatarSrc && props.logoType == null) || (props.logoType == 'avatar') ) {

            logoElement = <YapAssetImage asset={{ src: logoAvatarSrc, crop: logoAvatar ? logoAvatar.crop : null, description: props.logoDescription }} width={40} height={40} cornerRadius={10}></YapAssetImage>

            const iconStyle = {
                width: '40px',
                height: '40px',
                backgroundImage: 'url(' + logoAvatarSrc + ')',
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                borderRadius: '10px',
                border: '1px solid #00000015',
            }

            const iconElement = <div style={iconStyle}>{logoElement}</div>

            const bodyTextStyle = {
                display: '-webkit-box',
                overflow: 'hidden',
                "WebkitBoxOrient": "vertical",
                lineHeight: '1.2',
                fontWeight: 700,
                fontSize: '14px',
                textAlign: 'left'
            }
    
            bodyTextStyle.WebkitLineClamp = 2

            logoElement = (
                <Stack spacing={10} direction={"horizontal"} align={"center"} justify={"leading"} padding={0} paddingVertical={0} paddingHorizontal={0} >
                    {iconElement}
                    <Stack spacing={2} paddingBottom={2} paddingTop={2} align={"leading"} >
                        <span style={bodyTextStyle}>{props.institutionTitle ?? "University Name"}</span>
                        {/* <Text readonly={true} style={"titleHeaderCollegeSmall"} color={"primary"} align="left" content={props.institutionTitle ?? "University Name"}></Text> */}
                    </Stack>
                </Stack>
            )
        }

        /**
         * Text Content
         */

        let emptyTextElements = [
            <div key={"medium1"} style={mediumRectangle}/>,
            <div key={"medium2"} style={mediumRectangle}/>,
            <div key={"small"} style={smallRectangle}/>
        ]

        let textElements = (
            <span style={bodyTextStyle} className={"message-body"}>
                {title}
            </span>
        )

        let defaultCaption = MessageHeaderComponent.dateFormatter(new Date())

        return (
            <Section padding="20" paddingVertical="0" paddingBottom={"20"} id="message-card" title="Header">
                <div style={cardStyle}>
                    <Stack paddingTop={20} paddingHorizontal={20} paddingBottom={15} spacing={5}>
                        <Stack direction="horizontal" spacing={10} align={"leading"}>

                            <div style={{"width": "150px"}}>
                            <Stack direction={"vertical"} align={"leading"} spacing={6}>
                                {logoElement ? logoElement : emptyLogo}
                                {title ? textElements : emptyTextElements }
                            </Stack>
                            </div>
                            {img ? <Image crop={img ? img.crop : null} src={imgSrc} description={props.imageDescription} width={imageWidth} height={imageHeight} cornerRadius={8}></Image> : emptyImage}
                        </Stack>

                        <div style={divider} />

                        <Stack direction="horizontal" spacing={5} paddingHorizontal={0} align={"leading"} spaceItems={"between"}>
                            <Text id="time" content={props.caption ?? defaultCaption} font={font} style={"caption"} canEditStyle={false} align="left" color="secondary" />
                            <span style={iconStyle}>{bookmarkIcon}{moreIcon}</span>
                        </Stack>
                    </Stack>
                </div>
            </Section>
        )
    }

    encode(model, props) {
        return props
    }
}

export default MessageCardComponent