import React from 'react'
import { YapContentBuilderIconProviderContext as CBMessageBuilderIconProviderContext } from '@yapstudios/yap-content-builder'
const cbMore = (color) => {
    return (
        <svg aria-hidden={true} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 9.5C8.82843 9.5 9.5 8.82843 9.5 8C9.5 7.17157 8.82843 6.5 8 6.5C7.17157 6.5 6.5 7.17157 6.5 8C6.5 8.82843 7.17157 9.5 8 9.5Z" fill="#1E1E1E"/>
            <path d="M1.5 9.5C2.32843 9.5 3 8.82843 3 8C3 7.17157 2.32843 6.5 1.5 6.5C0.671573 6.5 0 7.17157 0 8C0 8.82843 0.671573 9.5 1.5 9.5Z" fill="#1E1E1E"/>
            <path d="M14.5 9.5C15.3284 9.5 16 8.82843 16 8C16 7.17157 15.3284 6.5 14.5 6.5C13.6716 6.5 13 7.17157 13 8C13 8.82843 13.6716 9.5 14.5 9.5Z" fill="#1E1E1E"/>
        </svg>
    )
}

const cbCompose = (color) => {
    return (
        <svg aria-hidden={true} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={color} d="M15.3 7.69C14.67 7.06 13.65 7.06 13.03 7.69L8.44001 12.28C8.38001 12.34 8.33001 12.42 8.31001 12.5L7.66001 14.67C7.60001 14.86 7.66001 15.06 7.79001 15.2C7.89001 15.3 8.03001 15.36 8.16001 15.36C8.21001 15.36 8.26001 15.36 8.31001 15.34L10.48 14.69C10.56 14.66 10.64 14.62 10.7 14.56L15.3 9.97C15.93 9.34 15.93 8.32 15.3 7.7V7.69ZM14.55 9.21L10.05 13.71L8.95001 14.04L9.28001 12.94L13.78 8.44C13.99 8.23 14.34 8.23 14.55 8.44C14.76 8.65 14.76 9 14.55 9.21Z" />
            <path className={color} d="M6.59001 15.38H1.37001C0.740011 15.38 0.230011 14.87 0.230011 14.24V2.95C0.230011 2.32 0.740011 1.81 1.37001 1.81H12.05C12.68 1.81 13.19 2.32 13.19 2.95V6.6C13.19 6.9 12.94 7.15 12.64 7.15C12.34 7.15 12.09 6.9 12.09 6.6V2.95L1.36001 2.91L1.32001 14.23L6.59001 14.27C6.89001 14.27 7.14001 14.52 7.14001 14.82C7.14001 15.12 6.89001 15.37 6.59001 15.37V15.38Z"/>
            <path className={color} d="M3.15001 4.1C2.85001 4.1 2.60001 3.84999 2.60001 3.54999V1.17C2.60001 0.869995 2.85001 0.619995 3.15001 0.619995C3.45001 0.619995 3.70001 0.869995 3.70001 1.17V3.54999C3.70001 3.84999 3.45001 4.1 3.15001 4.1Z"/>
            <path className={color} d="M10.27 4.1C9.97 4.1 9.72 3.84999 9.72 3.54999V1.17C9.72 0.869995 9.97 0.619995 10.27 0.619995C10.57 0.619995 10.82 0.869995 10.82 1.17V3.54999C10.82 3.84999 10.57 4.1 10.27 4.1Z"/>
            <path className={color} d="M6.71 4.1C6.41 4.1 6.16 3.84999 6.16 3.54999V1.17C6.16 0.869995 6.41 0.619995 6.71 0.619995C7.01 0.619995 7.26 0.869995 7.26 1.17V3.54999C7.26 3.84999 7.01 4.1 6.71 4.1Z" />
        </svg>
    )
}

const cbTrash = (color) => {
    return (
        <svg aria-hidden={true} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_9448_61064)">
                <path className={color} fillRule="evenodd" clipRule="evenodd" d="M5.83696 1.30836C5.94397 1.20134 6.0891 1.14123 6.24044 1.14123H9.66412C9.81546 1.14123 9.9606 1.20134 10.0676 1.30836C10.1746 1.41537 10.2347 1.5605 10.2347 1.71184V3.44651H5.66983V1.71184C5.66983 1.5605 5.72995 1.41537 5.83696 1.30836ZM4.5286 3.44651V1.71184C4.5286 1.25783 4.70895 0.822419 5.02999 0.501386C5.35102 0.180354 5.78643 0 6.24044 0H9.66412C10.1181 0 10.5535 0.180354 10.8746 0.501386C11.1956 0.822419 11.376 1.25783 11.376 1.71184V3.44651H14.8453C15.1604 3.44651 15.4159 3.70199 15.4159 4.01713C15.4159 4.33227 15.1604 4.58774 14.8453 4.58774H1.15057C0.835428 4.58774 0.579956 4.33227 0.579956 4.01713C0.579956 3.70199 0.835428 3.44651 1.15057 3.44651H4.5286ZM2.79164 5.73336C3.10435 5.69428 3.38954 5.91609 3.42862 6.2288L4.50737 14.8588H11.4885L12.5673 6.2288C12.6063 5.91609 12.8915 5.69428 13.2042 5.73336C13.5169 5.77245 13.7388 6.05764 13.6997 6.37035L12.5584 15.5002C12.5227 15.7857 12.28 16 11.9922 16H4.00364C3.71587 16 3.47313 15.7857 3.43744 15.5002L2.29621 6.37035C2.25712 6.05764 2.47893 5.77245 2.79164 5.73336Z" />
            </g>
            <defs>
                <clipPath id="clip0_9448_61064">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

const cbLabelInfo = (color) => {
    return (
        <svg aria-hidden={true} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={color} d="M9.27997 11.9199C9.27997 11.9799 9.26997 12.0299 9.23997 12.0799C9.20997 12.1299 9.17997 12.1799 9.12997 12.2199C9.07997 12.2599 9.02997 12.2899 8.95997 12.3099C8.89997 12.3299 8.82997 12.3399 8.75997 12.3399H7.37997C7.28997 12.3399 7.20997 12.3299 7.12997 12.2999C7.04997 12.2699 6.97997 12.2299 6.90997 12.1799C6.84997 12.1299 6.79997 12.0699 6.76997 11.9999C6.73997 11.9299 6.71997 11.8599 6.71997 11.7899V7.6599C6.71997 7.5499 6.76997 7.4399 6.86997 7.3599C6.96997 7.2799 7.09997 7.2399 7.22997 7.2399H8.75997C8.89997 7.2399 9.02997 7.2799 9.11997 7.3599C9.21997 7.4399 9.26997 7.5499 9.26997 7.6599V11.9199H9.27997Z" fill="#1E1E1E"/>
            <path className={color} d="M9.14998 4.7999C9.14998 5.4299 8.63998 5.9499 7.99998 5.9499C7.36998 5.9499 6.84998 5.4299 6.84998 4.7999C6.84998 4.1699 7.36998 3.6499 7.99998 3.6499C8.62998 3.6499 9.14998 4.1699 9.14998 4.7999Z" fill="#1E1E1E"/>
            <path className={color} fillRule="evenodd" clipRule="evenodd" d="M8.00003 15.9999C6.41003 15.9999 4.88003 15.5299 3.56003 14.6499C2.24003 13.7699 1.22003 12.5299 0.61003 11.0599C3.03984e-05 9.59993 -0.15997 7.99993 0.16003 6.43993C0.47003 4.87993 1.23003 3.46993 2.35003 2.33993C3.47003 1.21993 4.88003 0.459926 6.44003 0.149926C8.00003 -0.160074 9.59003 -7.45654e-05 11.06 0.609926C12.53 1.21993 13.77 2.23993 14.65 3.55993C15.53 4.87993 16 6.41993 16 7.99993C16 10.1399 15.17 12.1499 13.66 13.6599C12.15 15.1699 10.14 15.9999 8.00003 15.9999ZM7.99488 1.08993C7.5466 1.09023 7.09831 1.13027 6.65003 1.21993C5.31003 1.48993 4.08003 2.13993 3.11003 3.10993C2.14003 4.07993 1.49003 5.29993 1.22003 6.64993C0.95003 7.99993 1.09003 9.36993 1.61003 10.6399C2.14003 11.9099 3.01003 12.9799 4.15003 13.7399C5.29003 14.4999 6.62003 14.8999 7.99003 14.8999C9.83003 14.8999 11.57 14.1799 12.87 12.8799C14.17 11.5799 14.89 9.83993 14.89 7.99993C14.89 6.62993 14.49 5.29993 13.73 4.15993C12.97 3.01993 11.9 2.13993 10.63 1.61993C9.79154 1.27055 8.89327 1.09057 7.99488 1.08993ZM7.99488 1.08993C7.9966 1.08993 7.99831 1.08993 8.00003 1.08993H7.99003C7.99165 1.08993 7.99327 1.08993 7.99488 1.08993Z" fill="#1E1E1E"/>
        </svg>
    )
    
}

const cbTick = (color) => {
    return (
        <svg aria-hidden={true}  width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={color} d="M5.10543 12C4.90531 12 4.69185 11.9179 4.53176 11.7675C4.49173 11.7401 4.46505 11.6991 4.43837 11.6581L0.250148 7.36468C-0.0833828 7.03644 -0.0833828 6.48939 0.250148 6.14748C0.570338 5.80557 1.10399 5.80557 1.43752 6.14748L5.10543 9.90751L14.5625 0.25133C14.896 -0.0905799 15.4163 -0.0769035 15.7499 0.25133C16.0834 0.579563 16.0834 1.12662 15.7499 1.46853L5.69246 11.7401C5.53236 11.9043 5.31889 11.9863 5.10543 11.9863V12Z" />
        </svg>               
    )
}

const cbWarning = (color) => {
    return (
        <svg aria-hidden={true}  width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_10_128" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
                <path d="M16 0H0V16H16V0Z" fill="white"/>
            </mask>
            <g mask="url(#mask0_10_128)">
                <path className={color} fillRule="evenodd" clipRule="evenodd" d="M0 8C0 3.58 3.58 0 8 0C12.42 0 16 3.58 16 8C16 12.42 12.42 16 8 16C3.58 16 0 12.42 0 8ZM8.00113 10.0249C7.69547 10.0249 7.45093 9.7953 7.45093 9.50828V3.91159C7.45093 3.62457 7.69546 3.39496 8.00113 3.39496C8.30679 3.39496 8.55133 3.62457 8.55133 3.91159V9.50828C8.55133 9.7953 8.3068 10.0249 8.00113 10.0249ZM8.00079 12.6622C8.43182 12.6622 8.78125 12.3128 8.78125 11.8818C8.78125 11.4507 8.43182 11.1013 8.00079 11.1013C7.56976 11.1013 7.22034 11.4507 7.22034 11.8818C7.22034 12.3128 7.56976 12.6622 8.00079 12.6622Z"/>
            </g>
        </svg>  
    )
}

/**
 * IconProvider for College Board.
 * 
 * This component should wrap any content that needs College Board specific icons
 */
function CBIconProvider(props) {

    const cbIcons = {
        'menu.more'            : cbMore,
        'menu.edit'            : cbCompose,
        'menu.trash'           : cbTrash,
        'label.info'           : cbLabelInfo,
        'asset.example.tick'   : cbTick,
        'asset.example.warning': cbWarning,      
        'validation.checkmark' : null,
        'validation.asterisk'  : null,
        'validation.info'      : null,
        'validation.warning'   : null,
        'validation.error'     : null,
    }

    const value = {
        get: (icon, color) => {
            let cbicon = cbIcons[icon]
            return (cbicon) ? cbicon(color ?? "color-tint") : null
        }
    }

    return <CBMessageBuilderIconProviderContext.Provider value={value}>{props.children}</CBMessageBuilderIconProviderContext.Provider>
}

export default CBIconProvider