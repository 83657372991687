import { useState, useEffect } from 'react'
import { CBMessageBuilder, CBPage, CBMessageBuilderIconProviderContext, CBMessageValidations, CBContentPreviewsModal, DefaultMessageTemplate, UniversityComponent, useCBPageState, CBMessageFeedPreview, CBMessagePreview, LearnMoreButtonComponent, RequestInformationButtonComponent, MessageHeaderComponent, TextComponent } from 'cb-message-builder'
import { fileUploader, loadAssets, groupAssets, deleteAsset, updateLibraryAsset } from './AppData'
import { v4 as uuid } from 'uuid';
import 'cb-message-builder/dist/index.css'
import { pageOne, pageTwo, pageFour } from "./ExistingData.js"

const App = () => {
    const [pages, setPages] = useState([])
    const [assets, setAssets] = useState({})
    const [showPreview, setShowPreview] = useState(false)

    useEffect( () => {
        // Load assets for the institution with the id 'test'
        loadAssets('test').then(
                (value) => {
                    setAssets(value)            
                },
                (error) => {
                    console.log(error)
                }
        )
    }, [])

    // Define info message for the university component to instruct a user
    // where that content can be edited
    UniversityComponent.infoMessage = (<span>
                                        University profile information can be updated in the <a href="">Profile Screen</a>
                                       </span>)

    
    // Toggle whether image alt text can be edited
    MessageHeaderComponent.canEditImageAltText = true

    /*
     * Field title customisations
     */
    // MessageHeaderComponent.title = "Add Your Title.."
    // MessageHeaderComponent.imageTitle = "Add Your Image.."
    // TextComponent.title = "Add Your Text..."

    /*
     * Action button title customisations
     */
    //
    // If need to customise button titles when not editable.
    //
    // These are defined as static class variables so any changes to 
    // these labels applies to all existing messages.
    //
    // LearnMoreButtonComponent.title = "Learn!"
    // RequestInformationButtonComponent.title = "Connect"

    const defaultTemplate = new DefaultMessageTemplate({
        
        // Define the action button, possible options are:
        // - learnMore          - [deeplink] Deeplink to institution page. Default title 'Explore More', Title & URL cannot be edited by default. 
        // - requestInformation - [external] External browser web link. Default title 'Request Info'. Title & URL cannot be edited by default. 
        // - embeddedRequestInformation - [external] External browser web link. Default title 'Request Info'. Title & URL cannot be edited by default. 
        // - custom             - [internal] Internal college board action. Default title 'Action Button'. Both fields can be edited by user by default.
        // - null               - Dont show an action
        action: 'embeddedRequestInformation', 

        // Default action button url
        actionURL: "https://collegeboard.org",    

        // Properties to change default editing behaviour of action buttons
        actionCanEditTitle: true,
        actionCanEditURL: true,
        //actionTitle: "Apply", // Used as default when title is editable
        
        title: "Add Message Title",
        body: "Add Your Message",
        headerImage: {
            placeholderMessage: "Add Header Image"
        },

        date: new Date(),
        image: {
            footnote: "Add Image Description",
            placeholderMessage: "Add Message Image"
        },
        institution: {
            name: "University of Virginia",
            location: "Virginia",
            iconURL: "https://firebasestorage.googleapis.com/v0/b/cb-prototype-4e3e7.appspot.com/o/UVA%20-%20Logo%20-%20Square%20-%20Large.png?alt=media&token=8eed43e1-e049-4827-aff9-36de97543ee7"
        }
    })

 
    // onAssetImport callback
    //
    // Passes in the follow parameters 
    // - file: HTML File object that represents the file selected by the user
    // - asset: Suggested dictionary for convenience to represent the asset object. Will contain id, type, title, dimensions.
    // - album: Album the user is currently in 
    // - progressCallback: Callback to call when upload progress changes. progressCallback(percentComplete);
    // - completeCallback: Callback to call when upload is complete. completeCallback(error)
    // - errorCallback: Callback to call if theres an error errorCallback(message)
    //
    // Return value:
    //   Function to call that will be called if the upload is cancelled by the user
    //
    const onAssetImport = (file, asset, album, progressCallback, completeCallback, errorCallback) => {
        console.log('onAssetImport')
        console.log(asset)

        // Create a new asset
        var asset = asset
        asset.album = album.name
        
        const uploadTask = fileUploader({
            institutionId: "test",
            file: file,
            asset: asset,
            progressCallback: progressCallback,
            completeCallback: completeCallback,
            errorCallback: errorCallback,
            setAssets: setAssets
        })

        // Return value to onAssetImport is a function that will be called if the upload is cancelled by the user
        return () => {
            uploadTask.cancel()

            // Show cancelled message here if required
            errorCallback('User Cancelled!')
        }
    }

    // onAssetDeleteCallback callback
    // Called when an asset is deleted
    //
    // Passes the following parameters
    // - asset: Asset dictionary represeting the object
    const onAssetDelete = (asset) => {
        console.log('onAssetDelete')
        console.log(asset)
        setAssets({...deleteAsset(asset, "test")})
    }

    // onAssetChangeCallback callback
    // Called when an asset's properties are updated by the user.
    //
    // Passes the following parameters
    // - asset: Asset dictionary represeting the object
    const onAssetChange = (asset) => {
        console.log('onAssetChange')
        console.log(asset)
        setAssets({...updateLibraryAsset(asset, "test")})
    }

    // onPagesUpdated callback
    // Called when an the user makes changes to message content.
    const onPagesUpdated = (pages, userUpdated) => {
        setPages(pages)
        console.log(`onPagesUpdated length [${JSON.stringify(pages).length}]`)
        console.log(`userUpdated [${userUpdated}]`)
        console.log(JSON.parse(JSON.stringify(pages)))
    }

    // Handle done event
    // Called when user taps done button and passes validation
    const onDone = (validationPassed) => {
        console.log(`onDone validationPassed [${validationPassed}]`)
        if (validationPassed) {
            alert('Saved!')
        } else {
            //console.warn("Validation failed")
        }
    }

    // Show library assets in one big list by passing as a flat array
    //
    // Here its converting from a firebase collection to an array to pass
    let libraryAssets = []
    for (const key in assets) {
        let asset = assets[key]
        if (asset.title == 'kitte') {
            asset.canDelete = false
        } else if (asset.title == 'grumpyCat') {
            asset.canEdit = false
        } else if (asset.title == 'fatCat') {
            asset.canDelete = false
            asset.canEdit = false
        }
        libraryAssets.push(asset);
    }

    // Message validations check functions
    // The applyValidation method on the template will return each component type in the template, 
    // and can switch on the type to return the appropriate validations
    let validations = defaultTemplate.applyValidation( (componentType) => {
        switch (componentType) {
            case 'message-header':
                return [
                        CBMessageValidations.fieldRequired('title'),
                        CBMessageValidations.fieldRequired('coverImage', { propertyKey: 'src', description: 'An image is required to be selected' }),
                        CBMessageValidations.maximumLengthWarning('title', { length: 40 }),
                        CBMessageValidations.imageDimensionsWarning('coverImage', { minimumWidth: 2048, minimumHeight: 2048 }),

                        // Image Validator
                        // CBMessageValidations.image('coverImage', { 

                        //     // Optional. Defaults to field name
                        //     // title: 'Image Validation',

                        //     // Required. Message to present when validation fails
                        //     description: (asset) => { return `The file type should be png but is ${asset.src ? fileExtension(asset.src) : 'unknown'}` }, 

                        //     // Required. The validation
                        //     check: (asset) => {
                        //         return asset.src ? fileExtension(asset.src) == 'png' : false
                        //     },

                        //     // Optional. If provided will show when the image is provided and passes validation
                        //     // validDescription: 'The file type of png is correct',

                        //     // Optional. If provided will show when the image is null
                        //     // infoDescription: 'The file type is recommended to be png',

                        //     // Optional (defaults to warning) - 'error' or 'warning'
                        //     // type: 'warning',
                            
                        // }),                        
                       ]
            case 'paragraph':
                return [
                        CBMessageValidations.fieldRequired('title'),
                        CBMessageValidations.urlProtocolsRequired('title', { protocols: ['https', 'http'] }),
                        CBMessageValidations.minimumLengthWarning('title', { length: 50 })
                ]
            case 'image':
                return [
                        CBMessageValidations.fieldRequired('image',  { propertyKey: 'src', description: 'An image is required to be selected' }),
                        CBMessageValidations.fieldRequired('footnote'),
                        CBMessageValidations.imageDimensionsWarning('image', { minimumWidth: 2048, minimumHeight: 2048 }),
                ]
            case 'action':
                return [ 
                        CBMessageValidations.fieldRequired('title'),
                        CBMessageValidations.fieldRequired('url'),
                        CBMessageValidations.urlProtocolsRequired('url', { protocols: ['https', 'http'] }), 
                       ]
            case 'actionRequestInformation':
            return [ 
                    CBMessageValidations.fieldRequired('title', { allowDefault: true, filter: (props) => { return props.canEditTitle == true } }),
                    ]

            case 'actionEmbeddedRequestInformation':
                return [ 
                        CBMessageValidations.fieldRequired('title', { allowDefault: true }),
                        ]
            
            default:
                break
        }
        return null
    })

    return (
        <CBPage containerClassName={"container"}>
            {/* <CBHeader title={"New Message"}/> */}
            <CustomHeader title={"New Message"} 
                        loadPageAction={() => {
                                setPages([pageOne])
                        }} 
                        newPageAction={() => { 
                                setPages([]) 
                        }}
                        previewAction={() => {
                                setShowPreview(true) 
                        }}/>
            <CBMessageBuilder 
                validations={validations} 
                assets={libraryAssets} 
                defaultTemplate={defaultTemplate} 
                pages={pages} 
                onPagesUpdated={onPagesUpdated} 
                onAssetImport={onAssetImport} 
                onAssetDelete={onAssetDelete} 
                onAssetChange={onAssetChange}
                onDone={onDone} 
                
                /** Disable / enable cropping */
                canCrop={true}

                /** Disable / enable multi upload */
                canMultiUpload={true}

                />

            <CBContentPreviewsModal show={showPreview} onClose={setShowPreview} >
                <CBMessagePreview name="Content Preview" page={pages ? pages[0] : null}/>
            </CBContentPreviewsModal>           
        </CBPage>
    )
}

/**
 * Example of a custom header that triggers the done action from a custom button.
 * 
 * This example is for when need complete control over what actions are shown & the style of them
 */
function CustomHeader({ title, loadPageAction, newPageAction, previewAction }) {
    // Get the done action that is set for a CBPage
    //
    // You could choose to send the done action to something up further in your hierarchy
    // or return a header here with actions as to where its used 
    let { doneAction, containerClassName }  = useCBPageState()

    let headerStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        alignContent: 'flex-end',
        margin: '0 auto 0 auto'
    }

    let doneStyle = {
        padding: '12px 14px 12px 14px',
        backgroundColor: '#eeeeee',
        color: 'black',
        fontWeight: 500,
        fontSize: '11pt',
        borderRadius: '8px',
        border: '0px'
    }

    let buttonStyle = {
        padding: '12px 14px 12px 14px',
        backgroundColor: 'white',
        color: 'black',
        fontWeight: 500,
        fontSize: '11pt',
        borderRadius: '8px',
        border: '0px'
    }

    let buttonContainerStyle = {
        display: 'flex',
        gap: '5px'
    }

    return (
        <div className={containerClassName} style={headerStyle}>
            <h1>{title}</h1>
            <div style={buttonContainerStyle}>
                <button style={buttonStyle} onClick={previewAction}>Preview</button>
                <button style={buttonStyle} onClick={newPageAction}>New Page</button>
                <button style={buttonStyle} onClick={loadPageAction}>Load Page</button>
                <button style={doneStyle} onClick={doneAction}>Done</button>
            </div>
        </div>
    )
}

function fileExtension(url) {
    // Extract the pathname from the URL
    let pathname = new URL(url).pathname;
    
    // Split the pathname by '.' and get the last part
    let parts = pathname.split('.');
    return parts.length > 1 ? parts.pop() : null;
}


export default App
