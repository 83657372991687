import React from 'react'
import { YapContentPreview } from '@yapstudios/yap-content-builder'
import { Section, Stack, Text, Image, YapAssetImage, Gallery, Video, Calendar, Quiz, ActionButton, Info, ZStack } from '@yapstudios/yap-content-builder'
import { styles, fonts } from "./CBStyles.js"
import MessageCardComponent from './CBMessageCardComponent.js'

class InstitutionHeaderComponent {
    constructor(title, subtitle) {
        this.type = 'institution-header'
        this.title = title
        this.subtitle = subtitle
    }

    defaultProps() {
        return {}
    }

    decode(props, { style }) {

        // Support passing as src or asset object for backward compatibility
        let image = props.background
        let imageSrc = image ? (image.src ?? image) : null
        let logo = props.logo
        let logoSrc = logo ? (logo.src ?? logo) : null

        const zstackposition = {
            position: 'absolute',
            top: '350px'
        }

        const placeholderStyle = {
            backgroundImage: 'url(' + style.images.placeholder + ')',
            backgroundSize: '16px 16px',
        }

        const prounounceLogo = image == null && logo == null 

        const placeholderImageStyle = {
            ...placeholderStyle,
            opacity: prounounceLogo ? 0.85 : 1.0
        }

        let borderSize = prounounceLogo ? 4 : 3;

        const imageStroke = (
            <div style={{
                width: '90px',
                height: '90px',
                border: prounounceLogo ? `2px solid #D7D7D7` : `2px solid #00000015`  ,
                borderRadius: '18px',
                boxSizing: 'border-box'
            }}></div>
        )

        const imageBorder = (
            <div style={{
                boxShadow: prounounceLogo ? '0px 2px 40px #00000040' : '',                
                width: '90px',
                height: '90px',
                background: 'white',
                outline: `${borderSize}px solid white`,
                borderRadius: '18px'
            }}></div>
        )

        return (
            <Section padding="0" paddingVertical="0" paddingBottom={20} id="section-header" title="Header">
                <Stack>
                    <ZStack height={425} paddingTop={0} paddingHorizontal={20} paddingBottom={35} spacing={5}>
                        <Image placeholder={true} placeholderStyle={placeholderStyle} crop={image ? image.crop : null} src={imageSrc} description={props.backgroundDescription} height={400} />
                        <div style={zstackposition}>
                            <ZStack height={93}>
                                {imageBorder}
                                <Image crop={logo ? logo.crop : null} placeholder={logo == null} placeholderStyle={placeholderImageStyle} cornerRadius={18} src={logoSrc} description={props.logoDescription}  width={90} height={90} />
                                {imageStroke}
                            </ZStack>
                        </div>
                    </ZStack>

                    <Stack paddingHorizontal={50} spacing={10}>
                        <Text style={"titleHeaderCollege"} content={props.title ?? this.title} />
                        <Text style={"headline"} content={props.subtitle ?? this.subtitle} />
                    </Stack>
                </Stack>
            </Section>
        )
    }

    encode(model, props) {
        return props
    }
}

class DividerComponent {
    constructor(title, subtitle) {
        this.type = 'divider'
        this.title = title
        this.subtitle = subtitle
    }

    defaultProps() {
        return {}
    }

    decode(props) {
        const divider = { height: "1px", borderTop: "1px solid #e3e3e3", marginTop: "8px", marginBottom: "4px", marginLeft: '20px', marginRight: '20px' }

        return (
            <Section id="section-header" title="Header">
                <div style={divider} />
            </Section>
        )
    }
}

class ParagraphWithMore {
    constructor() {
        this.type = 'paragraph-with-more'
    }

    defaultProps() {
        return {}
    }

    decode(props) {
        const bodyTextStyle = {
            display: '-webkit-box',
            overflow: 'hidden',
            "WebkitBoxOrient": "vertical",
            lineHeight: '1.4',
            fontWeight: 400,
            fontSize: '17px',
            textAlign: 'left'
        }

        bodyTextStyle.WebkitLineClamp = 3

        return (
            <Section id="section" paddingHorizontal={20} paddingVertical={15}>
                <span style={bodyTextStyle}>
                    {props.title}
                </span>
            </Section>
        )
    }
}


class SectionHeader {
    constructor() {
        this.type = 'section-header'
    }

    defaultProps() {
        return {}
    }

    decode(props) {
        return (
            <Section id="section-header" paddingHorizontal={20} paddingVertical={15} paddingBottom={10}>
                <Stack spacing={2} justify={"leading"} align={"leading"}>
                    <Text style={"h2"} content={props.title} />
                    <Text style={"body"} content={props.subtitle} color={"secondary"} />
                </Stack>
            </Section>
        )
    }
}

class TagSegmentedControl {
    constructor() {
        this.type = 'tag-segmented-control'
    }

    defaultProps() {
        return {}
    }

    decode(props) {
        const tab = {
            padding: '14px',
            paddingLeft: '21px',
            paddingRight: '21px',
            fontWeight: 600,
            borderRadius: '8px'
        }
        const selectedTab = {
            ...tab,
            backgroundColor: '#324DC7',
            color: '#ffffff',
        }

        const unselectedTab = {
            ...tab,
            backgroundColor: '#F2F2F2',
        }

        return (
            <Section id="section-header" paddingHorizontal={20} paddingVertical={10}>
                <Stack spacing={10} direction={"horizontal"} justify={"leading"} align={"leading"}>
                    <div style={selectedTab}>
                        {"All"}
                    </div>
                    <div style={unselectedTab}>
                        {"Saved"}
                    </div>
                    <div style={unselectedTab}>
                        {"Unread"}
                    </div>
                </Stack>
            </Section>
        )
    }
}

const institutionComponents = [
    new InstitutionHeaderComponent(),
    new DividerComponent(),
    new ParagraphWithMore(),
    new SectionHeader(),
    new TagSegmentedControl(),
    new MessageCardComponent()
]

/*
* Presents a preview of an institution profile page
* @param {page} Page data
* @param {width} (Optional) width
* @returns {React.ReactElement} The view
*/
const CBInstitutionPreview = (props) => {
    let sections = [
        {
            'id': 'institution-header',
            'type': 'institution-header',
            'props': { title: props.title, 
                       subtitle: props.subtitle, 
                       logo: props.logo, 
                       logoDescription: props.logoDescription, 
                       background: props.background,
                       backgroundDescription: props.backgroundDescription }
        },
        {
            'id': 'divider',
            'type': 'divider'
        },
        {
            'id': 'institution-description',
            'type': 'paragraph-with-more',
            'props': { title: props.description ?? "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."}
        },
        {
            'id': 'divider',
            'type': 'divider'
        },
        {
            'id': 'section-header',
            'type': 'section-header',
            'props': { title: 'Messages', subtitle: `Sent to you from ${props.title}` }
        },
        {
            'id': 'message',
            'type': 'message-card',
            'props': { 'title': '' }
        },        
        {
            'id': 'message',
            'type': 'message-card',
            'props': { 'title': '' }
        },  
        {
            'id': 'message',
            'type': 'message-card',
            'props': { 'title': '' }
        },                
        // {
        //     'id': 'tag-segmented-control',
        //     'type': 'tag-segmented-control',
        //     'props': { tags: ['All', 'Saved', 'Unread'] }
        // },
    ]

    let page = {
        id: 'page-1',
        sections: sections
    }

    return <YapContentPreview components={institutionComponents} width={props.width} styles={styles} fonts={fonts} {...props} page={page} />
}

export default CBInstitutionPreview