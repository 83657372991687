import React from 'react'
import { Section, Stack, Text, Image, Gallery, Video, Calendar, Quiz, ActionButton, Info, ZStack } from '@yapstudios/yap-content-builder'
import assetURL from './assetURL'

function formatDate(date) {
    const months     = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    const monthsLong = ['JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE', 'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'];
    const days       = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    const daysLong   = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];

    const dayOfWeek  = days[date.getDay()];
    const dayOfMonth = date.getDate();
    const month      = months[date.getMonth()];
    const hours      = date.getHours() % 12 || 12;
    const minutes    = date.getMinutes().toString().padStart(2, '0');
    const ampm      = date.getHours() >= 12 ? 'PM' : 'AM';

    const showTime = false

    if (showTime) {
        return `${dayOfWeek} ${dayOfMonth} ${month}, ${hours}:${minutes} ${ampm}`;
    } else {
        return `${month} ${dayOfMonth}`;
    }
}

class MessageHeaderComponent {
    
    static dateFormatter = formatDate
    static title = "Message Title"
    static imageTitle = "Header Image"
    static canEditImageAltText = true

    constructor(title, date, imageSrc, imagePlaceholderMessage) {
        this.defaultTitle = title
        this.imageSrc = imageSrc
        this.imagePlaceholderMessage = imagePlaceholderMessage
        this.type = 'message-header'
        this.defaultDate = date ?? new Date()
    }

    defaultProps() {
        return {
            title: this.defaultTitle ?? "New Message",
            caption: MessageHeaderComponent.dateFormatter(this.defaultDate),
            coverImage: { src: this.imageSrc, contentMode: "cover", placeholderMessage: this.imagePlaceholderMessage }
        }
    }


    titlesForProp() {
        return { 
            title: MessageHeaderComponent.title,
            caption: 'Caption',
            coverImage: MessageHeaderComponent.imageTitle
        }
    }
    

    decode(props, { style }) {

        var title = props.title
        var titleStyle = props.titleStyle ?? "titleHeader"
        var caption = props.caption
        var image = props.coverImage ?? { src: null, contentMode: "cover" }

        // var emptyImage = <div style={{ "backgroundColor" : "#ECECEC", height: '350px', width: '100%' }}></div>

        var placeholderStyle = { }
        if (props.isPreview) {
            placeholderStyle['backgroundColor'] = style.colors.placeholder
        }
        return (
            <Section padding="0" paddingVertical="0" id="section-header" title="Header">
                <Stack paddingTop={100} paddingHorizontal={20} paddingBottom={25} spacing={5}>
                    <Text id="title" componentTitle={MessageHeaderComponent.title} content={title} style={titleStyle} canEditStyle={false} align="left" color="primary" />
                    <Text id="caption" readonly={true} content={caption} style="captionUppercased" align="left" color="secondary" />
                </Stack>
                <ZStack height={350}>
                    <Image componentTitle={MessageHeaderComponent.imageTitle} canEditAltText={MessageHeaderComponent.canEditImageAltText} placeholder={true} placeholderStyle={placeholderStyle} placeholderMessage={this.imagePlaceholderMessage} src={image.src} description={image.description} height="350" contentMode={image.contentMode} id="coverImage" accessibilityLabel="Icon" {...image}></Image>
                    <Stack height={350} direction={"vertical"} align={"center"} justify={"center"} spacing={"spaceBetween"}>
                        {image.src == null && <Text id="placeholder" content={image.placeholderMessage} style={"body"} readonly={true}  align="center" color="rgba(255,255,255,0.4)" />}
                    </Stack>
                </ZStack>
            </Section>
        )
    }

    encode(model, props) {
        switch (model['id']) {
            case 'title':
                props['title'] = model.content
                props['titleStyle'] = model.style
                break;
            case 'caption':
                props['caption'] = model.content
                break;
            case 'coverImage':
                props['coverImage'] = model
            default:
                break
        }

        return props
    }
}

class UniversityComponent {
    static infoMessage = null
    static title = "University Profile"

    constructor(title, subtitle, iconURL) {
        this.type = 'institution'
        this.defaultTitle = title
        this.defaultSubtitle = subtitle
        this.defaultIconURL = iconURL
    }

    defaultProps() {
        return {
            title: this.defaultTitle,
            subtitle: this.defaultSubtitle,
            iconURL: this.defaultIconURL
        }
    }

    decode(props, { style, isPreview }) {
        
        // Support passing as src or asset object for backward compatibility
        var icon = props.iconURL
        var iconSrc = icon ? (icon.src ?? icon) : null

        const placeholderStyle = {
            backgroundColor: style.colors.placeholder,
            backgroundImage: isPreview ? 'url(' + style.images.placeholder + ')' : null,
            backgroundSize: '16px 16px',
            borderRadius: 8 + 'px',
            width: '40px',
            height: '40px',
        }

        const placeholderElement = <div style={placeholderStyle}></div>

        let iconImage = <Image crop={icon ? icon.crop : null} placeholder={icon == null} cornerRadius={10} src={iconSrc}  width={40} height={40} />

        const iconStyle = {
            width: '40px',
            height: '40px',
            borderRadius: '10px',
            border: '1px solid #00000015',
        }


        const iconElement = <div style={iconStyle}>{iconImage}</div>

        let content = (
            <Stack spacing={10} direction={"horizontal"} align={"center"} justify={"leading"} borderBottom="1px solid" borderColor="separator" padding={20} paddingVertical={20} paddingHorizontal={20} >
                    {icon ? iconElement : placeholderElement}
                <Stack spacing={2} paddingBottom={2} paddingTop={2} align={"leading"} >
                    <Text readonly={true} style={"headline"} color={"primary"} align="left" content={props.title}></Text>
                    <Text readonly={true} style={"subhead"} color={"secondary"} content={props.subtitle}></Text>
                </Stack>
            </Stack>
        )

        let withInfo = (                    
                <Info title={UniversityComponent.title} message={UniversityComponent.infoMessage}>
                    {content}
                </Info>
        )

        return (
                <Section padding={0} id="action-button" title="Action Button">
                    {UniversityComponent.infoMessage ? withInfo: content}
                </Section>
            )
    }

    encode(model, props) {
        props['title'] = model.title
        props['defaultSubtitle'] = model.subtitle
        return props
    }
}

class ActionButtonComponent {
    constructor(url, defaultOptions) {
        this.type = 'action'
        this.defaultURL = url
        this.defaultOptions = defaultOptions ?? {}
    }


    defaultProps() {
        let options = this.defaultOptions
        let defaults = {
            url: this.defaultURL,
            title: options.title ?? "Action",
        }
        if (options.canEditTitle == false) {
            defaults.canEditTitle = false
        }
        if (options.canEditURL == false) {
            defaults.canEditURL = false
        }
        return defaults
    }

    decode(props) {
        return (
            <Section padding={20} paddingVertical={20} paddingHorizontal={20} id="action-button" title="Action Button">
                    <ActionButton title={props.title} url={props.url} canEditText={props.canEditTitle != null ? props.canEditTitle : true} canEditURL={props.canEditURL != null ? props.canEditURL : true} />
            </Section>
        )
    }

    encode(model, props) {
        props['title'] = model.title
        props['url'] = model.url
        return props
    }

}


class EmbeddedRequestInformationButtonComponent {
    static placeholderURL = null
    static title = "Request Info"

    constructor(url, defaultOptions) {
        this.type = 'actionEmbeddedRequestInformation'
        this.defaultURL = url
        this.defaultOptions = defaultOptions ?? {}
    }

    defaultProps() {
        let options = this.defaultOptions

        var defaults = {
            url: this.defaultURL
        }

        if (options.canEditTitle == true) {
            defaults.title = (options.title ?? EmbeddedRequestInformationButtonComponent.title)
            defaults.canEditTitle = true
        }

        if (options.canEditURL == true) {
            defaults.canEditURL = true
        }
        
        return defaults
    }

    titlesForProp() {
        return {
            url: 'URL'
        }
    }

    decode(props) {
        // If title key exists, use that otherwise use class level var to display title.
        const title = props.title !== undefined ? props.title : EmbeddedRequestInformationButtonComponent.title

        return (            
            <Section padding={20} paddingVertical={20} paddingHorizontal={20} id="request-information-action-button" title="Action Button">
                <ActionButton id="request-info" componentTitle={title} title={title} url={props.url ?? EmbeddedRequestInformationButtonComponent.placeholderURL}  canEditText={props.canEditTitle != null ? props.canEditTitle : false} canEditURL={props.canEditURL != null ? props.canEditURL : false} />
            </Section>
        )
    }

    encode(model, props) {
        props['url'] = model.url

        if (props.canEditTitle == true)  {
            props['title'] = model.title
        }

        return props
    }
}


class RequestInformationButtonComponent {
    static placeholderURL = null
    static title = "Request Info"

    constructor(url, defaultOptions) {
        this.type = 'actionRequestInformation'
        this.defaultURL = url
        this.defaultOptions = defaultOptions ?? {}
    }

    defaultProps() {
        let options = this.defaultOptions

        var defaults = {
            url: this.defaultURL
        }

        if (options.canEditTitle == true) {
            defaults.title = (options.title ?? RequestInformationButtonComponent.title)
            defaults.canEditTitle = true
        }

        if (options.canEditURL == true) {
            defaults.canEditURL = true
        }
        
        return defaults
    }

    titlesForProp() {
        return {
            url: 'URL'
        }
    }

    decode(props) {
        // If title key exists, use that otherwise use class level var to display title.
        const title = props.title !== undefined ? props.title : RequestInformationButtonComponent.title

        return (            
            <Section padding={20} paddingVertical={20} paddingHorizontal={20} id="request-information-action-button" title="Action Button">
                <ActionButton id="request-info" componentTitle={title} title={title} url={props.url ?? RequestInformationButtonComponent.placeholderURL}  canEditText={props.canEditTitle != null ? props.canEditTitle : false} canEditURL={props.canEditURL != null ? props.canEditURL : false} />
            </Section>
        )
    }

    encode(model, props) {
        props['url'] = model.url

        if (props.canEditTitle == true)  {
            props['title'] = model.title
        }

        return props
    }
}


class LearnMoreButtonComponent {
    static placeholderURL = "No URL"
    static title = "Explore More"

    constructor(url, defaultOptions) {
        this.type = 'actionLearnMore'
        this.defaultURL = url
        this.defaultOptions = defaultOptions ?? {}
    }

    defaultProps() {
        let options = this.defaultOptions
        let defaults = {
            url: this.defaultURL,
            title: options.title,
        }
        if (options.canEditTitle == true) {
            defaults.canEditTitle = true
        }
        if (options.canEditURL == true) {
            defaults.canEditURL = true
        }
        return defaults
    }

    titlesForProp() {
        return { 
            url: 'URL'
        }
    }

    decode(props) {
        let disableInteraction = {
            'pointerEvents' : 'none'
        }
        const title = props.canEditTitle && props.title ? props.title : LearnMoreButtonComponent.title
        return (            
            // <div style={props.canEditTitle == true ? null : disableInteraction} >
                <Section padding={20} paddingVertical={20} paddingHorizontal={20} id="action-button" title="Action Button">
                        <ActionButton componentTitle={title} title={title} url={props.url ?? LearnMoreButtonComponent.placeholderURL}  canEditText={props.canEditTitle != null ? props.canEditTitle : false} canEditURL={props.canEditURL != null ? props.canEditURL : false} />
                </Section>
            // </div>            
        )
    }

    encode(model, props) {
        props['url'] = model.url
        return props
    }

}


class TextComponent {
    static title = "Message Text"

    constructor(text, style) {
        this.type = 'paragraph'
        this.defaultText = text ?? "Lorem Ipsum"
        this.defaultStyle = style ?? "body"
    }

    defaultProps() {
        return {
            title: this.defaultText,
            style: this.defaultStyle,
            align: "left"
        }
    }

    titlesForProp() {
        return { 
            title: TextComponent.title
        }
    }
    

    decode(props) {
        return <Section padding="0" paddingVertical="0" id="text" title="Header">
            <Stack padding={20} paddingBottom={0} spacing={20}>
                <Text componentTitle={TextComponent.title} id="header" canEditStyle={false} content={props.title} style={props.style} align={props.align} color="primary" />
            </Stack>
        </Section>
    }

    encode(model, props) {
        props['title'] = model.content
        props['style'] = model.style
        props['align'] = model.align
        return props
    }

}

class SingleImageComponent {
    static title = "Message Image"

    constructor( src, footnote, placeholderMessage, fullBleed ) {
        this.src = src
        this.footnote = footnote
        this.placeholderMessage = placeholderMessage
        this.fullBleed = fullBleed
        this.type = 'image'
    }

    defaultProps() {
        return {
            fullBleed: this.fullBleed,
            footnote: this.footnote ?? "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
            image: { src: this.src, contentMode: "cover", placeholderMessage: this.placeholderMessage }
        }
    }

    titlesForProp() {
        return { 
            image: SingleImageComponent.title,
            footnote: 'Message Image Description'
        }
    }
    
    decode(props) {
        var paddingHorizontal = props.fullBleed ? 0 : 20
        var paddingHorizontalFootnote = props.fullBleed ? 20 : 0
        return (
            <Section padding="0" paddingVertical="0" id="section-image" title="Single Image">
                <Stack paddingVertical={20} paddingHorizontal={paddingHorizontal} paddingBottom={20} spacing={10}>
                    <ZStack height={350}>
                        <Image componentTitle={SingleImageComponent.title} placeholder={true} height="350" contentMode={props.image.contentMode} src={props.image.src} id="image" accessibilityLabel="Image" {...props.image}></Image>
                        <Stack height={350} direction={"vertical"} align={"center"} justify={"center"} spacing={"spaceBetween"}>
                            {props.image.src == null && <Text id="placeholder" content={props.image.placeholderMessage} style={"body"} readonly={true}  align="center"  color="rgba(255,255,255,0.4)"  />}
                        </Stack>
                    </ZStack>                    
                    <Text validationProperty={'footnote'} componentTitle={this.titlesForProp()['footnote']} canEditStyle={false} id="footnote" paddingHorizontal={paddingHorizontalFootnote} content={props.footnote} style="footnote" align="left" color="secondary" />
                </Stack>
            </Section>
        )
    }

    encode(model, props) {
        switch (model['id']) {
            case "footnote":
                props['footnote'] = model.content
                break
            case "image":
                props['image'] = model
                break
            default:
                break
        }
        return props
    }

}


class GalleryComponent {
    constructor(images) {
        this.images = []
        this.type = 'gallery'
    }
  

    defaultProps() {
        return {
            images: [ 

            ]
        }
    }
  
    decode(props) {
        var images = props.images ?? this.images
        var paddingHorizontal = props.fullBleed ? 0 : 20
        var paddingHorizontalFootnote = props.fullBleed ? 20 : 0
        return <Section padding="0" paddingTop="20" id="gallery-section" title="Gallery Image">
                <Gallery id={'gallery'} imageSrcs={images} numberOfImages={3}/>
        </Section>
    }
  
    encode(model, props) {
        switch (model['id']) {
            case "gallery":
                props['images'] = model.images
                break
            default:
                break
        }
        return props
    }
  
  }
  

class QuizComponent {
    constructor() {
        this.type = 'quiz'
    }

    defaultProps() {
        return {
            title: "Question",
            correctAnswer: 0
        }
    }

    decode(props) {
        return <Section padding={20} paddingBottom="0" id="section-quiz" title="Quiz">
            <Quiz id="quiz-item" title={props.title} correctAnswer={props.correctAnswer} answers={3} />
        </Section>
    }

    encode(model, props) {
        props.title = model.title
        props.correctAnswer = model.correctAnswer
        return props
    }
}

class CalendarComponent {
    constructor() {
        this.type = 'calendar'
    }

    defaultProps() {
        return {
            title: 'Event Title',
            date: '27 Mar 2023'
        }
    }

    decode(props) {
        return (
            <Section padding={20} paddingBottom="0" id="calendar" title="Calendar">
                <Calendar title={props.title} date={props.date} id="calendar" />
            </Section>
        )
    }

    encode(model, props) {
        props.title = model.title
        return props
    }
}

class VideoComponent {
    constructor() {
        this.type = 'video'
    }

    defaultProps() {
        return {
            src: ''
        }
    }

    decode(props) {
        return <Section height="350" padding="20" paddingBottom={0} id="section-video" title="Video">
            <Video id={"video"} src={props.src} />
        </Section>
    }

    encode(model, props) {
        props['src'] = model.src
        return props
    }
}

/**
 * These are all available components. For use within templates and for users to drag and drop.
 */
const components = [
    new MessageHeaderComponent(),
    new TextComponent(),
    new SingleImageComponent(),
    new QuizComponent(),
    new VideoComponent(),
    new CalendarComponent(),
    new ActionButtonComponent(),
    new UniversityComponent(),
    new GalleryComponent(),
    new LearnMoreButtonComponent(),
    new RequestInformationButtonComponent(),
    new EmbeddedRequestInformationButtonComponent()
]

/**
 * These are components available for users to drag and drop.
 */
const componentTemplates = [
    {
        title: 'Text',
        icon: 'text',
        columns: 1,
        components: [
            { template: new TextComponent("Header", "h2"), thumbnail: assetURL('section-header.png') },
            { template: new TextComponent("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"), thumbnail: assetURL('paragraph.png') }
        ]
    },

    {
        title: 'Images',
        icon: 'gallery',
        columns: 2,
        components: [
            { template: new SingleImageComponent(), thumbnail: assetURL('image-single.png') },
            //{ template: new SingleImageComponent(true), thumbnail: assetURL('image-single-full-width.png') },
            { template: new GalleryComponent([]), thumbnail: assetURL('gallery.png') },
        ]
    },

    {
        title: 'Quiz',
        icon: 'quiz',
        columns: 1,
        components: [
            { template: new QuizComponent(), thumbnail: assetURL('quiz.png') },
        ]
    },

    {
        title: 'Video',
        icon: 'video',
        columns: 1,
        components: [
            { template: new VideoComponent(), thumbnail: assetURL('video-1.png') },
        ]
    },

    {
        title: 'Calendar',
        icon: 'calendar',
        columns: 1,
        components: [
            { template: new CalendarComponent(), thumbnail: assetURL('calendar.png') },
        ]
    },

]

export { components, componentTemplates, MessageHeaderComponent, TextComponent, SingleImageComponent, QuizComponent, CalendarComponent, VideoComponent, ActionButtonComponent, RequestInformationButtonComponent, EmbeddedRequestInformationButtonComponent, LearnMoreButtonComponent, UniversityComponent }
