import React from 'react'
import assetURL from './assetURL'
import { MessageHeaderComponent, TextComponent, SingleImageComponent, QuizComponent, ActionButtonComponent, LearnMoreButtonComponent, RequestInformationButtonComponent, EmbeddedRequestInformationButtonComponent, UniversityComponent } from './CollegeboardComponentLibrary'
import { YapContentValidations } from '@yapstudios/yap-content-builder'
/**
 * Default Template
 */
class DefaultMessageTemplate {
  constructor(props) {
    this.props = props ?? { editable: false }
    this.isEditable = false//this.props.editable

    var action = 'action' in props ? props.action : 'custom'

    switch (action) {
        case 'learnMore':
          this.actionButton = {
            canDelete: this.isEditable,
            canMove: this.isEditable,
            component: new LearnMoreButtonComponent(props.actionURL, { title: props.actionTitle, canEditTitle: props.actionCanEditTitle, canEditURL: props.actionCanEditURL })
          };
          break;
        case 'requestInformation':
          this.actionButton = {
            canDelete: this.isEditable,
            canMove: this.isEditable,
            component: new RequestInformationButtonComponent(props.actionURL, { title: props.actionTitle, canEditTitle: props.actionCanEditTitle, canEditURL: props.actionCanEditURL })
          };
          break;
        case 'embeddedRequestInformation':
            this.actionButton = {
              canDelete: this.isEditable,
              canMove: this.isEditable,
              component: new EmbeddedRequestInformationButtonComponent(props.actionURL, { title: props.actionTitle, canEditTitle: props.actionCanEditTitle, canEditURL: props.actionCanEditURL })
            };
            break;          
        case 'custom':
        case 'userAction':
            this.actionButton = {
              canDelete: this.isEditable,
              canMove: this.isEditable,
              component: new ActionButtonComponent(props.actionURL, { title: props.actionTitle, canEditTitle: props.actionCanEditTitle, canEditURL: props.actionCanEditURL })
            };
            break;          
        default:
          this.actionButton = null;
          break;
      }
  }

  // Call the provided function for each component and apply the validation
  applyValidation(callback) {
    return this.components().flatMap( ({ component }) => {
        YapContentValidations._currentComponent = component
        let result = callback(component.type)
        YapContentValidations._currentComponent = null
        return result
    }).filter (a => a)
  }

  components() {
    var institution = this.props.institution ?? { name: "University", location: "Location" }
    var image = this.props.image ?? { src : null, footnote: null, placeholderMessage: null, fullBleed: false }
    var headerImage = this.props.headerImage ?? { src : null, footnote: null, placeholderMessage: null, fullBleed: false }
    return [
      { canDelete: false, canMove: false, component: new MessageHeaderComponent(this.props.title, this.props.date, headerImage.src, headerImage.placeholderMessage ?? "abcd") },      
      { canDelete: false, canMove: false, component: new UniversityComponent(institution.name, institution.location, institution.iconURL) },
      { canDelete: this.isEditable, canMove: this.isEditable, component: new TextComponent(this.props.body ?? "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam") },
      //{ canDelete: this.isEditable, canMove: this.isEditable, component: new SingleImageComponent(image.src, image.footnote, image.placeholderMessage, image.fullBleed) },
      this.actionButton
    ].filter(x => x); // Remove null
  }
}

/**
 * Blank 
 */
class BlankMessageTemplate {
  components() {
    return [
      { canDelete: false, canMove: false, component: new MessageHeaderComponent() },      
    ]
  }
}

class TextTemplate {
  components() {
    return [
      { canDelete: false, canMove: false, component: new MessageHeaderComponent() },      
      new TextComponent("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"),
    ]
  }
}

class ImageAndTextTemplate {
  components() {
    return [
      { canDelete: false, canMove: false, component: new MessageHeaderComponent() },      
      new TextComponent("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"),
      new SingleImageComponent(),
      new ActionButtonComponent()
    ]
  }
}

class QuizTemplate {
  components() {
    return [
      { canDelete: false, canMove: false, component: new MessageHeaderComponent() },      
      new TextComponent("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"),
      new QuizComponent(),
      new QuizComponent(),
      new QuizComponent(),
    ]
  }
}

const templateLibrary = [
    {
      name: 'Basic',
      templates: [
        { template: new BlankMessageTemplate(), title: "Blank Message", thumbnail: assetURL('template-empty.png')  },
        { template: new ImageAndTextTemplate(), title: "Text", thumbnail: assetURL('template-basic-text.png')  },
        { template: new ImageAndTextTemplate(), title: "Image & Text", thumbnail: assetURL('template-basic-text-image.png')  }
      ]
    },
  
    {
      name: 'Quizes',
      templates: [
        { template: new QuizTemplate(), title: "Image & Text", thumbnail: assetURL('template-quiz.png')  }
      ]
    },
  ]
  

export { templateLibrary , BlankMessageTemplate , TextTemplate , ImageAndTextTemplate , QuizTemplate, DefaultMessageTemplate }