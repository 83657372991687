import React from 'react'

import { initializeApp } from 'firebase/app';
import { getFirestore, collection, doc, setDoc, getDoc, query, deleteDoc, getDocs } from "firebase/firestore";
import { getStorage, ref, uploadBytes, uploadBytesResumable, getDownloadURL, } from "firebase/storage";

/*
 * --------------
 * Firebase Setup
 * --------------
 */
// TODO: Replace the following with your app's Firebase project configuration
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCu80kpTPj8VwQff3WHM1GsBevW-fDpBKo",
    authDomain: "cb-prototype-4e3e7.firebaseapp.com",
    projectId: "cb-prototype-4e3e7",
    storageBucket: "cb-prototype-4e3e7.appspot.com",
    messagingSenderId: "251480700217",
    appId: "1:251480700217:web:04dec91c325426375f64e8"
};

const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

// Local data store
var assetDataStore = {}

// Create a root reference
const storage = getStorage();

/**
 * --------------
 * Asset Library Updating 
 * --------------
 */
const updateLibraryAsset = (asset, insitutionId) => {
    const docRef = doc(db, "assets", insitutionId);

    let data = {}
    data[asset.id] = asset
    setDoc(docRef, data, { merge: true });

    assetDataStore[asset.id] = asset
    return assetDataStore
}

const deleteAsset = (asset, insitutionId) => {
    const docRef = doc(db, "assets", insitutionId);

    delete assetDataStore[asset.id]
    setDoc(docRef, assetDataStore, { merge: false });

    return assetDataStore
}

const loadAssets = async (insitutionId) => {
    const docRef = doc(db, "assets", insitutionId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        let data = docSnap.data()
        assetDataStore = data
        return data
    } else {
        console.log("No such document!");
        return {}
    }
}

/**
 * Asset Uploader Helper
 */
const fileUploader = ({institutionId, file, asset, progressCallback, completeCallback, errorCallback, setAssets}) => {

    // Create a reference to 'mountains.jpg'
    const fileRef = ref(storage, 'branding-assets/' + institutionId + '-' + file.name);
    const uploadTask = uploadBytesResumable(fileRef, file);

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on('state_changed',
        (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes);

            // Update download UI
            progressCallback(progress);

            switch (snapshot.state) {
                case 'paused':
                    console.log('Upload is paused');
                    break;
                case 'running':
                    console.log('Upload is running');
                    break;
            }
        },
        (error) => {
            if (error.code != 'storage/canceled') {
                errorCallback(error.message);
            } 
        },
        () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                
                // Update asset property src with new download URL 
                const newAsset = {
                    ...asset,
                    src: downloadURL
                }
                
                // Update assets with provided callback
                setAssets({...updateLibraryAsset(newAsset, 'test')});

                // Update UI progress to be completed
                completeCallback(newAsset);
            });
        }
    );

    return uploadTask
}

function groupAssets(assets, groupBy) {
    const groupedAssets = [];

    for (const assetKey in assets) {
        const asset = assets[assetKey];
        const groupByName = asset[groupBy];

        const existingGroup = groupedAssets.find(group => group['name'] === groupByName);

        if (existingGroup) {
            existingGroup.assets.push(asset);
        } else {
            const newGroup = {
                name: groupByName,
                assets: [asset]
            };
            groupedAssets.push(newGroup);
        }
    }

    return groupedAssets;
}


export { fileUploader, updateLibraryAsset, deleteAsset, loadAssets, groupAssets }